header {
  margin: 20px 0 15px 0;
  display: grid;
  grid-template-columns: 230px 230px 1fr 400px;

  @media screen and (max-width: 1235px) {
    grid-template-columns: 1fr 1fr 330px;
  }

  @media screen and (max-width: 770px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 450px) {
    display: none;
  }

  .title,
  .trending-container {
    height: 172px;
  }

  .title,
  .infos-mkt,
  .trending-container,
  .categories {
    background: rgba(20, 20, 20, 0.85);
    padding: 10px;
    border-radius: 14px;
    margin-right: 10px;
    box-shadow: $shadow;
    display: grid;
    align-items: center;
    backdrop-filter: blur(3px);
    border-bottom: 2px solid $color1;

    ul {
      height: 100%;
    }
  }
  .title {
    position: relative;
    overflow: hidden;

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 42px;
      background: rgba(0, 0, 0, 0.5);
      top: 0;

      @media screen and (max-width: 1150px) {
        top: -4px;
      }
    }
    h1 {
      font-family: $font2;
      font-size: 1.4rem;
      color: $color1;
      z-index: 20;
      position: relative;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      background: -webkit-linear-gradient(360deg, $color1, #0debc6);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      transform: translateY(-8px) !important;

      a {
        letter-spacing: 1px;
        &:hover {
          -webkit-text-fill-color: $color1;
          color: $color1 !important;
          font-weight: 100;
        }
      }

      @media screen and (max-width: 1150px) {
        transform: translateY(-9px) !important;
      }

      @media screen and (max-width: 450px) {
        font-size: 1.2rem;
      }

      img {
        height: 18px;
        object-fit: cover;
        margin-right: 5px;
        transform: translate(2px, 0px);
      }
    }
    .infos {
      display: grid;
      grid-template-columns: 90% 10%;
      height: 120%;
      font-size: 0.9rem;
      background: none !important;
      z-index: 0 !important;

      ul {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 95%;
      }
      .img-help {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        margin-top: 6px;

        img {
          height: 20px;
          cursor: pointer;
          transition: 0.2s;

          &:hover {
            transform: translateX(1px);
            filter: brightness(150%);
          }
        }
        a {
          margin-right: 10px !important;
        }
      }
    }
  }
  .infos-mkt {
    font-size: 0.95rem;
    align-items: start;
    border-bottom: none;
    padding: 4px;
    background: none !important;

    .global-mkt {
      display: flex;
      background: none;

      strong {
        margin-left: 6px;
      }
    }
    .fearGreed {
      strong {
        font-size: 1.1rem;
      }
    }
  }
  .global-chart {
    border-radius: 14px;
    overflow: hidden;
    box-shadow: $shadow;
    overflow: hidden;

    @media screen and (max-width: 1235px) {
      display: none;
    }

    .notes-container {
      height: 100%;
      width: 399px;
      background: $black1;
      border-bottom: 2px solid $color1;
      border-radius: 0 0 13px 13px;
      overflow: hidden;

      h4 {
        text-align: center;
        background: $black2;
        height: 25px;
        letter-spacing: 1px;
      }

      form {
        height: 90%;
        position: relative;

        textarea {
          background: transparent;
          border: none;
          padding: 5px;
          overflow-y: scroll;
          resize: none;
          color: $white1;
          font-size: 1.1rem;
          width: 100% !important;
          height: 100%;

          &::selection {
            background: $color1;
            color: $black1;
          }

          &::-webkit-scrollbar {
            // background: $white1;
            border-radius: 50px;
            width: 1px;
          }
          &::-webkit-scrollbar-track {
            border-radius: 50px;
          }
          &::-webkit-scrollbar-thumb {
            background: $color1;
            border-radius: 50px;
            height: 10px;
          }

          &:focus {
            outline: none;
          }
        }
        input[type="submit"] {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 100px;
          height: 30px;
          background: $color1;
          border: none;
          cursor: pointer;
          color: $black2;
          font-size: 1.01rem;
          border-radius: 4px 0 0 0;
          transition: 0.2s;

          &:focus {
            outline: 1px solid #0debc6;
          }

          &:hover {
            filter: brightness(130%);
            transform: translateX(1px);
          }
        }
      }
    }

    text {
      font-size: 0.7rem !important;
      letter-spacing: 1px;
    }
    .custom-tooltip {
      background: rgb(59, 59, 59);
      padding: 10px;
    }
  }
  .trending-container {
    display: block;
    overflow: hidden;

    @media screen and (max-width: 770px) {
      display: none;
    }

    h3 {
      text-align: center;
      letter-spacing: 1px;
      margin-top: -8px;
      color: #05cbc8;

      &::after {
        content: "";
        position: absolute;
        height: 35px;
        width: 100%;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        background: rgba(0, 0, 0, 0.5);
        z-index: -1;
      }
    }

    .trend-list {
      margin-top: 14px;

      display: grid;
      grid-template-columns: 50% 50%;
      grid-template-rows: repeat(4, 30px);
      grid-template-areas:
        "el1 el1"
        "el2 el3"
        "el4 el5"
        "el6 el7";

      li {
        display: flex;
        align-items: center;
        height: 22px;

        &:first-child {
          grid-area: el1;
        }

        .rank {
          width: 10px;
          font-size: 0.85rem;
        }

        img {
          border-radius: 4px;
          height: 16px;
          margin: 0 8px 0 6px;
          filter: drop-shadow(0 0 4px rgba(250, 250, 250, 0.25));
        }
        p {
          font-size: 0.85rem;
          color: $white1;
        }
        span {
          font-size: 0.7rem;
          color: $white2;
          margin-left: 3px;
        }
      }
    }
  }
  .categories {
    display: block;
    overflow: hidden;

    h3 {
      text-align: center;
      letter-spacing: 1px;
      margin-top: -8px;

      a {
        color: #05cbc8;
      }

      &::after {
        content: "";
        position: absolute;
        height: 35px;
        width: 100%;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        background: rgba(0, 0, 0, 0.5);
        z-index: -1;
      }
    }
    ul {
      font-size: 0.94rem;
      display: grid;
      grid-template-columns: 50% 50%;
      height: auto;
      margin-top: 6px;

      a {
        display: flex;
        height: 28px;
        color: $white1;

        .img {
          margin-top: 2px;
          position: relative;
          width: 23px;

          img {
            height: 9px;
            position: absolute;
            opacity: 0.85;

            &:nth-child(1) {
              top: 2px;
              left: 3px;
            }
            &:nth-child(2) {
              left: -3px;
              top: 12px;
            }
            &:nth-child(3) {
              right: 6px;
              top: 12px;
            }
          }
        }
        .text {
          display: flex;
          align-items: center;
        }
      }
      .percent-change-container {
        margin-left: 4px;
      }
    }
  }
}
