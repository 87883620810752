@import url("https://fonts.googleapis.com/css2?family=Baloo+Bhaina+2&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Saira+Condensed:wght@500&display=swap");

$font1: "Saira Condensed", sans-serif;
$font2: "Baloo Bhaina 2", cursive;
$color1: #00b7b3;
$green2: rgb(0, 253, 148);
$green1: rgb(2, 172, 81);
$green: rgb(6, 214, 27);
$red1: rgb(255, 111, 86);
$red2: rgb(251, 69, 63);
$white1: rgb(219, 217, 217);
$white2: rgb(172, 172, 172);
$black1: rgb(31, 31, 31);
$black2: rgb(10, 10, 10);
$shadow: 0 5px 15px rgba(0, 0, 0, 0.322);

:export {
  color1: $color1;
  green2: $green2;
  green1: $green1;
  green: $green;
  red1: $red1;
  red2: $red2;
  black1: $black1;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $font1;

  &::selection {
    background: $color1;
    color: $black1;
  }
}

html {
  scroll-behavior: smooth;
  font-size: 110%;
}

body {
  background: url(../assets/wave.svg) center/cover;
  background-size: 2000%;
  color: $white1;
  max-width: 1360px;
  margin: 0 auto;
  min-height: 100vh;
  font-size: 1.01rem;

  &::-webkit-scrollbar {
    background: #333;
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 50px;
    background: $black1;
  }
  &::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, #00b7b3, #0debc6);
    border-radius: 50px;
    height: 30px;
  }
}
li {
  list-style: none;
}

a,
a:visited {
  text-decoration: none;
  color: $color1;

  &:hover {
    filter: brightness(150%);
  }
}
label {
  user-select: none;
}
input {
  &::selection {
    background: linear-gradient(90deg, $color1, #0debc6);
  }
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
img {
  user-select: none;
}
.loading {
  position: relative;
  text-align: center;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    animation: loading 0.85s infinite linear;

    @keyframes loading {
      to {
        transform: rotate(360deg);
      }
    }
  }
}
.top {
  margin: 20px auto 30px;
  text-align: center;
  animation: animLogo 1.2s infinite linear;
  user-select: none;

  img {
    height: 22px;
    transition: transform 0.4s;
    cursor: pointer;

    &:hover {
      transform: scale(1.3);
    }
  }

  @keyframes animLogo {
    50% {
      transform: translateY(3px);
    }
    100% {
      transform: translate(0);
    }
  }
}
