.table-container {
  text-align: center;
  color: $white1 !important;
  margin-top: -53px;

  &.mktvol {
    .table-header,
    .table-line {
      grid-template-columns: 320px 20px 106px 82px 82px 60px repeat(8, 1fr) 50px 50px 20px;

      @media screen and (max-width: 1150px) {
        grid-template-columns: 320px 20px 100px 82px 82px repeat(7, 1fr) 20px;
      }
      @media screen and (max-width: 1020px) {
        grid-template-columns: 276px 80px 80px 100px repeat(7, 1fr);
      }
      @media screen and (max-width: 850px) {
        grid-template-columns: 276px 80px 80px 100px repeat(4, 1fr);
      }
      @media screen and (max-width: 715px) {
        grid-template-columns: 220px 80px repeat(4, 1fr);
      }
      @media screen and (max-width: 540px) {
        grid-template-columns: 170px 80px repeat(4, 1fr);
      }
      @media screen and (max-width: 450px) {
        grid-template-columns: 130px 80px 1fr 1fr;
      }
    }
    .table-header {
      li:nth-child(6),
      li:nth-child(7),
      li:nth-child(15),
      li:nth-child(16) {
        display: block;

        @media screen and (max-width: 1150px) {
          display: none;
        }
      }
    }
    .table-line {
      p:nth-child(6),
      p:nth-child(7),
      p:nth-child(15),
      p:nth-child(16) {
        display: block;

        @media screen and (max-width: 1150px) {
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: 770px) {
    margin-top: -4px;
  }

  @media screen and (max-width: 450px) {
    margin-top: 10px;
  }

  .table-filters {
    display: grid;
    grid-template-columns: 451px 1fr 1fr;
    margin-bottom: 4px;

    @media screen and (max-width: 1235px) {
      grid-template-columns: 1fr 340px;
    }

    @media screen and (max-width: 770px) {
      grid-template-columns: 1fr;
      margin-right: 10px;
    }

    .table-filters-container {
      display: flex;
      background: $black1;
      border-radius: 10px;
      padding: 2px 6px;
      justify-content: space-between;
      align-items: center;
      height: 38px;
      border-bottom: 2px solid $color1;
      position: relative;

      .list-type {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -15px);
        color: $white2;
        font-size: 0.8rem;

        &::after {
          content: "";
          position: absolute;
          width: 136%;
          height: 12px;
          background: $black1;
          left: 50%;
          border-radius: 300px;
          transform: translate(-50%, 1px);
          z-index: -1;
        }
      }

      img {
        color: $color1;
        height: 22px;
        padding: 1px;
      }

      div,
      label {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 29px;
        width: 50px;

        &:hover {
          transform: scale(1.05) translateX(1px);
        }
      }

      @media screen and (max-width: 450px) {
        font-size: 0.7rem;
      }

      #mktv-btn {
        @media screen and (max-width: 1200px) {
          display: none;
        }
      }

      .stable-checkbox-container {
        display: flex;
        align-items: center;

        &:nth-child(2),
        &:nth-child(3) {
          @media screen and (max-width: 1200px) {
            display: none;
          }
        }

        #stableCoin,
        #volmkt {
          display: none;

          &:checked {
            ~ label {
              background: $black2;
            }
          }
          ~ label {
            transition: 0.15s;
            padding: 2px 10px;
            border-radius: 10px;
            cursor: pointer;

            &:hover {
              filter: brightness(130%);
            }
          }
        }
      }

      .fav-list {
        img {
          height: 20px;
        }
      }

      .no-list-btn,
      .fav-list,
      .shit-list,
      .all,
      .none {
        transition: 0.15s;
        border-radius: 10px;
        padding: 2px 10px;
        cursor: pointer;
        &:hover {
          filter: brightness(130%);
        }

        &.active {
          background: $black2;
        }
      }
    }
  }

  .table-header,
  .table-line {
    display: grid;
    grid-template-columns: 320px 20px 106px 82px 82px repeat(7, 1fr) 20px;

    @media screen and (max-width: 1020px) {
      grid-template-columns: 276px 80px 80px 100px repeat(7, 1fr);
    }
    @media screen and (max-width: 850px) {
      grid-template-columns: 276px 80px 80px 100px repeat(4, 1fr);
    }
    @media screen and (max-width: 715px) {
      grid-template-columns: 220px 80px repeat(4, 1fr);
    }
    @media screen and (max-width: 540px) {
      grid-template-columns: 170px 80px repeat(4, 1fr);
    }
    @media screen and (max-width: 450px) {
      grid-template-columns: 130px 80px 1fr 1fr;
    }
  }
  .table-header {
    height: 44px;
    align-items: center;
    width: 100%;
    z-index: 1000;
    transition: 0;

    li {
      transition: 0.25s;
      position: relative;

      &:active {
        transform: scale(1.03);
      }
    }

    .timeframe {
      &:nth-child(8),
      &:nth-child(9),
      &:nth-child(10),
      &:nth-child(11),
      &:nth-child(12),
      &:nth-child(13) {
        transform: translateY(-8px);
        font-size: 1.03rem;
      }

      span {
        position: absolute;
        bottom: -14px;
        font-size: 0.74rem;
        left: 50%;
        transform: translateX(-50%);
        color: rgb(161, 161, 161);
        letter-spacing: 0.05rem;
      }
    }

    &.active {
      position: fixed;
      max-width: 1360px;
      top: 0;
      background: rgba(52, 52, 52, 0.8);
      backdrop-filter: blur(2px);
      opacity: 0;
      top: -10px;
      animation: navAnim 0.3s forwards;
      border-bottom: 1px solid rgb(77, 77, 77);

      @keyframes navAnim {
        to {
          opacity: 1;
          top: 0;
        }
      }

      .top {
        visibility: visible !important;
      }
    }
    @media screen and (max-width: 1020px) {
      li:nth-child(2) {
        display: none;
      }
    }

    @media screen and (max-width: 850px) {
      li:nth-child(9),
      li:nth-child(10),
      li:nth-child(11) {
        display: none;
      }
    }
    @media screen and (max-width: 715px) {
      li:nth-child(4),
      li:nth-child(5) {
        display: none;
      }
    }
    @media screen and (max-width: 450px) {
      li:nth-child(12),
      li:nth-child(13) {
        display: none;
      }
    }

    span {
      input {
        width: 30px;
        font-family: $font1;
        background: none;
        border: none;
        outline: none;
        color: white;
        font-size: 0.95rem;
        color: $white1;
      }
    }

    .range-container {
      text-align: left;
      margin-left: 10px;
      display: grid;
      grid-template-columns: 66px 94px 70px 30px;
      align-items: center;

      @media screen and (max-width: 450px) {
        span {
          > span {
            display: none;
          }
        }
      }

      > span {
        cursor: pointer;
        transition: 0.1s;
        user-select: none;
        position: relative;
        font-size: 0.9rem;

        &:hover {
          span {
            transform: translateX(1px);
            filter: brightness(150%);
          }
        }
        &:active {
          transform: scale(1.08);
        }
      }

      .inputs-container {
        position: relative;
        height: 100%;

        @media screen and (max-width: 450px) {
          margin-left: -40px;
        }

        .number-indic {
          display: flex;
          width: 91px;
          justify-content: space-between;
          position: absolute;
          bottom: -9px;
          left: -8px;
          user-select: none;
        }
        .start,
        .end {
          color: grey;
          font-size: 0.65rem;
        }

        input {
          position: absolute;
          transform: translateX(-50%, -50%);
          top: 43%;
          left: -8px;
        }
      }

      input[type="range"] {
        appearance: none;
        background: linear-gradient(90deg, $color1, #0debc6);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
        border-radius: 50px;
        height: 6px;
        cursor: pointer;
        width: 90px !important;

        @media screen and (max-width: 1150px) {
          width: 70%;
        }
      }
      input[type="range"]::-webkit-slider-runnable-track {
        appearance: none;
        height: 10px;
        user-select: none;
        width: 90px;
      }
      input[type="range"]::-webkit-slider-thumb {
        appearance: none;
        width: 6px;
        height: 14px;
        transform: translateY(-2px);
        background: $white1;
        border-radius: 150px;
        cursor: grab;
        transition: 0.15s;
        z-index: 15;
        position: relative;

        &:hover {
          transform: scale(1.8) translateY(-2px);
        }
      }
      input[type="range"]::-webkit-slider-thumb:active {
        cursor: grabbing;
        transform: scale(2) translateY(-2px);
      }
      .input-search {
        position: relative;

        input {
          background: none;
          border: 1px solid grey;
          outline: none;
          transform: translate(9px, 1px);
          border-radius: 5px;
          padding: 3px 7px;
          width: 90px;
          color: $white1;
          font-family: $font1;
          letter-spacing: 1px;
          // font-size: 1.1rem;

          @media screen and (max-width: 750px) {
            display: none;
          }
        }
        img {
          position: absolute;
          right: -24px;
          top: 5px;
          height: 20px;
          width: 20px;
          cursor: pointer;
          transition: 0.15s;
          z-index: 100000000000;

          &:hover {
            transform: translateX(-1px);
          }
          &:active {
            transform: scale(1.1);
          }
        }
      }
      .top {
        margin: 0 !important;
        visibility: hidden;

        img {
          transform: translate(34px, 3px);
          height: 20px;

          @media screen and (max-width: 715px) {
            transform: translate(-78px, 2px);
          }

          @media screen and (max-width: 540px) {
            transform: translate(-78px, 2px);
          }
        }
      }
    }
    input[type="radio"] {
      display: none;

      &:checked ~ label {
        font-weight: 900;
        color: $color1;
      }
    }
    label {
      user-select: none;
      cursor: pointer;
      transition: 0.1s;

      &:hover {
        color: $color1;
      }
    }
    li:nth-child(6),
    li:nth-child(7),
    li:nth-child(15),
    li:nth-child(16) {
      display: none;
    }
    .box-filter {
      position: relative;

      &:hover {
        .volume-filter {
          visibility: visible;
          opacity: 1;
        }
      }

      .volume-filter {
        visibility: hidden;
        opacity: 0;
        transition: 0.15s;
        position: absolute;
        left: 80%;
        bottom: -15px;
        background: rgba(0, 0, 0, 0.7);
        backdrop-filter: blur(2px);
        border-radius: 4px;
        padding: 1px 4px;
        width: 80px;
        z-index: 1000;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);

        .bottom-part {
          display: grid;
          grid-template-columns: 1fr 20px 20px;
          align-items: center;

          input {
            width: 35px;
            background: transparent;
            border: none;
            outline: none;
            color: $color1;
            font-size: 1.05rem;
            text-align: right;
          }
          span {
            margin-left: 2px;
            transform: translateY(0px);
            text-align-last: left;
          }
          img {
            height: 20px;
            cursor: pointer;
            transition: 0.2s;

            &:hover {
              transform: scale(1.1);
            }
          }
        }
      }
    }
    .sum-number {
      color: grey;
      transform: translateX(-6px);

      @media screen and (max-width: 1050px) {
        display: none;
      }
    }
  }

  .table-line {
    min-height: 50px;
    align-items: center;
    border-top: 1px solid rgb(65, 65, 65);
    position: relative;
    color: $white1;
    transition: 0.1s ease;

    p:nth-child(6),
    p:nth-child(7),
    p:nth-child(15),
    p:nth-child(16) {
      display: none;
    }

    @media screen and (max-width: 1140px) {
      font-size: 0.8rem;

      p:nth-child(6),
      p:nth-child(7),
      p:nth-child(15),
      p:nth-child(16) {
        display: none;
      }
    }

    @media screen and (max-width: 850px) {
      p:nth-child(9),
      p:nth-child(10),
      p:nth-child(11) {
        display: none;
      }
    }
    @media screen and (max-width: 715px) {
      p:nth-child(4),
      p:nth-child(5) {
        display: none;
      }
    }

    @media screen and (max-width: 450px) {
      p:nth-child(11),
      p:nth-child(12),
      p:nth-child(13) {
        display: none;
      }
    }

    &:hover {
      background: rgba(128, 128, 128, 0.1);
    }
    .infos-container {
      display: grid;
      grid-template-columns: 19px 28px 28px 1fr;
      height: 100%;
      align-items: center;

      > img {
        height: 14px;
        display: block;
        cursor: pointer;
        transition: 0.2s;
        transform: translateX(3px);

        &:hover {
          transform: scale(1.25) translateX(3px);
        }
        @media screen and (max-width: 450px) {
          display: none;
        }
      }

      > p {
        text-align: center;
      }

      .img {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          object-fit: cover;
          height: 20px;
          border-radius: 4px;
        }
      }
      .infos {
        height: 100%;
        width: 100%;
        align-items: center;
        display: flex;
        text-align: left;
        margin-left: 3px;

        .chart-img {
          transform: translateY(0px);
          z-index: 10;
          position: relative;

          @media screen and (max-width: 770px) {
            display: none;
          }

          img {
            height: 17px;
          }
        }
        img {
          height: 15px;
          padding: 0 1px;
          transition: 0.1s;
          transform: translateY(2px);
          cursor: pointer;

          &:hover {
            transform: scale(1.3);
          }
        }
        a {
          transform: translateY(2px);
        }
        h4 {
          margin-left: 6px;
          letter-spacing: 1px;
          font-size: 1.05rem;
        }
        span {
          color: $white2;
          margin: 3px 2px 0 5px;
          font-size: 0.9rem;

          @media screen and (max-width: 715px) {
            display: none;
          }
        }
        .infos-popup {
          position: relative;

          @media screen and (max-width: 900px) {
            display: none;
          }

          a {
            img {
              transform: translateY(4px);
              height: 17px;

              @media screen and (max-width: 715px) {
                transform: translate(3px, 1px);
              }
            }
          }
          > p {
            margin: 0 10px;
          }
        }
      }
    }
    .signal-container {
      position: relative;

      @media screen and (max-width: 1020px) {
        display: none;
      }

      .sign-color {
        height: 12px;
        width: 12px;
        border-radius: 150px;
        margin: 0 auto;
        cursor: pointer;
        transition: 0.2s;

        &:hover {
          transform: scale(1.3);
          box-shadow: 0 2px 10px rgba(255, 255, 255, 0.437);
        }
      }

      .signal-container {
        position: absolute;
        z-index: 10;
        top: -60px;
        left: -126px;
        background: rgba(0, 0, 0, 0.322);
        backdrop-filter: blur(3px);
        border-radius: 10px;
        padding: 7px;
        box-shadow: 0 0 10px $black1;

        button {
          width: 108px;
          border-radius: 50px;
          border: none;
          height: 26px;
          margin-bottom: 3px;
          cursor: pointer;
          background-color: $color1;
          transition: 0.2s;

          &:hover {
            transform: translateX(2px);
            filter: brightness(140%);
          }
        }

        .form {
          input {
            width: 110px;
            height: 24px;
            display: block;
            border-radius: 50px;
            border: none;
            margin: 3px;
            background: $black1;
            color: $white1;
            padding: 3px;
            text-align: center;
            box-shadow: 0 2px 10px rgba(197, 197, 197, 0.234);

            &:focus {
              outline: none;
            }
          }
          input[type="texte"] {
            padding-left: 10px;
          }
          input::selection {
            color: $white2;
          }
          .btn-container {
            display: grid;
            grid-template-columns: 1fr 74%;
            align-items: center;
            gap: 1px;
            margin: 0 3px;

            input[type="submit"] {
              background: $color1;
              color: $black1;
              font-weight: bold;
              cursor: pointer;
              transition: 0.2s;
              width: 78px;
              height: 26px;

              &:hover {
                transform: translateX(2px);
                filter: brightness(140%);
              }
            }
            div {
              background: $black1;
              border-radius: 50px;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 26px;
              cursor: pointer;
              transition: 0.15s;

              &:hover {
                transform: scale(1.15);
                filter: brightness(120%);
              }

              img {
                height: 20px;
                width: 20px;
              }
            }
          }
        }
      }
    }
    .volume,
    .mktcap,
    .volumeMktcap {
      color: $white2;
    }
  }
  .delete-container {
    cursor: pointer;

    @media screen and (max-width: 1020px) {
      display: none;
    }

    img {
      height: 15px;
      color: $color1;
      display: block;
      transition: 0.15s;
      &:hover {
        transform: scale(1.4);
      }
    }
  }
  .percent-change-container {
    position: relative;
    span {
      height: 30px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 10px;
      width: 62px;
      opacity: 0.115;
      // COLOR
      color: green;
      color: rgb(64, 173, 64);
      color: rgb(166, 200, 166);
      color: rgb(255, 75, 75);
      color: rgb(249, 159, 159);
      color: rgb(248, 215, 215);
      // mix-blend-mode: difference;
    }
  }
}
